<template>
  <div class="center_box">
    <div class="fail_box">
      <img src="@/assets/img/verification/验证失败.png" alt="" />
      <div class="descript">
        <span>验证不通过</span>
        <span>文件未存证，或所填存证编号与源文件不匹配</span>
      </div>
      <el-button type="primary" @click="handleClick" class="btnSub"
        >返回重新验证</el-button
      >
    </div>
  </div>
</template>

<script >
export default {
  data() {
    return {};
  },
  props: {
    selectItem: {
      type: String,
    },
  },
  methods: {
    handleClick() {
      this.$emit("comChange", {
        comName: this.selectItem == "可信存证校验" ? "form_ver" : "ShareQuery",
      });
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 520px) {
  .fail_box {
    height: 400px;
  }
}
.center_box {
  // display: block;
  height: 430px;
  .fail_box {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    .descript {
      display: flex;
      height: 60px;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      span {
        text-align: center;
        line-height: 25px;
      }
    }
    .el-button {
      display: flex;
      justify-content: center;
      margin-top: 50px;
    }
    .btnSub {
      margin-left: 0;
    }
  }
}
</style>